<template>
  <div class="body">
    <h1 align="center" class="title"> Política de Privacidade </h1>
    <section>
      <p class="text" align="center"><strong class="topic">Atualiza&ccedil;&atilde;o:</strong>&nbsp; 20 de janeiro de 2025&nbsp;</p>
      <p class="text" align="justify">Esta Pol&iacute;tica de Privacidade (&ldquo;Pol&iacute;tica&rdquo;) &eacute; o documento que demonstra o compromisso da <strong class="sub-topic">VIX Seminovos</strong>&nbsp;(&ldquo;n&oacute;s&rdquo; / &ldquo;nossa&rdquo; / &ldquo;VIXPar&rdquo;) 
        com a privacidade e seguran&ccedil;a das informa&ccedil;&otilde;es ao prestar regularmente nossos servi&ccedil;os, inclusive on-line. Nesta Pol&iacute;tica, descrevemos como os seus dados pessoais s&atilde;o coletados, utilizados, compartilhados e armazenados.</p>
      <p class="text" align="justify">com a privacidade e seguran&ccedil;a das informa&ccedil;&otilde;es ao prestar regularmente nossos servi&ccedil;os, inclusive <em>on-line</em>. Nesta Pol&iacute;tica, descrevemos como os seus dados pessoais s&atilde;o coletados, utilizados, compartilhados e armazenados.&nbsp;</p>
      <p>&nbsp;</p>
      <h1 class="topic">GLOSS&Aacute;RIO</h1>
      <p>&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Anonimiza&ccedil;&atilde;o:</strong>&nbsp;Utiliza&ccedil;&atilde;o de meios t&eacute;cnicos razo&aacute;veis e dispon&iacute;veis no momento do Tratamento, por meio dos quais um dado perde a possibilidade de associa&ccedil;&atilde;o, direta ou indireta, a um indiv&iacute;duo.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>:</strong>&nbsp;Pequenos arquivos enviados pelos sites, salvos no seu dispositivo, que armazenam as prefer&ecirc;ncias e outras pequenas informa&ccedil;&otilde;es, com a finalidade de personalizar sua navega&ccedil;&atilde;o de acordo com o seu perfil.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Dado Pessoal:</strong>&nbsp;&Eacute; toda informa&ccedil;&atilde;o relacionada a uma pessoa natural identificada ou identific&aacute;vel. Por exemplo: nome, endere&ccedil;o, e-mail, endere&ccedil;o IP, geolocaliza&ccedil;&atilde;o, n&uacute;mero do protocolo, n&uacute;mero da conta etc.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Encarregado:</strong>&nbsp;Pessoa indicada por n&oacute;s para atuar como canal de comunica&ccedil;&atilde;o entre a VIX Seminovos, voc&ecirc; e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados (ANPD).&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Endere&ccedil;o IP:</strong>&nbsp;Abreviatura de&nbsp;<em>Internet&nbsp;Protocol</em>.&nbsp;&Eacute; o conjunto alfanum&eacute;rico que identifica os dispositivos dos Usu&aacute;rios na Internet.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">LGPD:</strong>&nbsp;Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais &ndash; Lei n&ordm;. 13.709/18, que disp&otilde;e sobre o tratamento de dados pessoais em meios digitais ou n&atilde;o.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Site: </strong>&eacute; o endere&ccedil;o eletr&ocirc;nico&nbsp;<a class="link" href="https://seminovos.vix.com.br/"><u>https://seminovos.vix.com.br/</u></a>.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Titular dos dados pessoais:</strong>&nbsp;&Eacute; a pessoa f&iacute;sica a quem os dados pessoais se referem, no caso desta Pol&iacute;tica, o titular &eacute; voc&ecirc;.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Tratamento: </strong>Toda opera&ccedil;&atilde;o realizada com dados pessoais, como a coleta, produ&ccedil;&atilde;o,&nbsp;recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Usu&aacute;rio: </strong>&Eacute; o titular dos dados pessoais que navega e/ou se cadastra no site Vix Seminovos.&nbsp;</p>
      <p>&nbsp;</p>
      <h1 class="topic">SOBRE OS&nbsp;DADOS PESSOAIS COLETADOS&nbsp;</h1>
      <p>&nbsp;</p>
      <p class="text" align="justify">Os Dados poder&atilde;o ser coletados quando voc&ecirc; interage em nosso Site.&nbsp;</p>
      <p class="text" align="justify">N&oacute;s podemos utilizar seus Dados Pessoais da&nbsp;seguinte maneira:&nbsp;&nbsp;</p>
      <table class="table-dados-pessoais" border="1" cellspacing="0" style="width:100%; border:3px solid #2abad4;">
        <tbody> 
          <tr>
			<td style="background-color:#cdd625; padding:10px;">
			<p align="center" style="margin:0; color:#fff;"><strong>Finalidade</strong></p>
			</td>
			<td style="background-color:#cdd625; padding:10px;">
			<p align="center" style="margin:0; color:#fff;"><strong>Dados Pessoais</strong>&nbsp;</p>
			</td>
		</tr>
     <tr>
			<td style="padding:10px;width:70%;">
			<p align="center"><u>Cadastro&nbsp;</u></p>
			<p align="center">Para se cadastrar e efetuar login na &aacute;rea de clientes do Seminovos.&nbsp;</p>
			</td>
			<td style="padding:10px;width:70%;">
			<ul>
			<li align="justify">E-mail&nbsp;</li>
			<li align="justify">Nome Completo&nbsp;</li>
      <li align="justify">Telefone&nbsp;</li>
      <li align="justify">CPF&nbsp;</li>
			</ul>
      </td>			
		</tr>
    <tr>
			<td style="padding:10px;width:70%;">
			<p align="center"><u>Compra de ve&iacute;culo/ativos</u></p>
			<p align="center">Para que o titular/cliente efetue a compra de nossos ve&iacute;culos/ativos.&nbsp;</p>
			</td>
			<td style="padding:10px;width:70%;">
			<ul>
			<li align="justify">Nome&nbsp;</li>
			<li align="justify">RG&nbsp;</li>
      <li align="justify">CPF&nbsp;</li>
			<li align="justify">CNH&nbsp;</li>
      <li align="justify">Data de nascimento&nbsp;</li>
      <li align="justify">Estado civil&nbsp;</li>
      <li align="justify">E-mail&nbsp;</li>
      <li align="justify">Telefone&nbsp;</li>
      <li align="justify">Endere&ccedil;o&nbsp;</li>
			</ul>
      </td>			
		</tr>
    <tr>
			<td style="padding:10px;width:70%;">
			<p align="center"><u>Fale com o vendedor</u></p>
			<p align="center">Para que o titular/cliente envie as suas d&uacute;vidas.&nbsp;</p>
			</td>
			<td style="padding:10px;width:70%;">
			<ul>
			<li align="justify">Nome&nbsp;</li>
			<li align="justify">Telefone (WhatsApp)&nbsp;</li>
      <li align="justify">Foto&nbsp;</li>
			</ul>
      </td>			
		</tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Atualiza&ccedil;&atilde;o e veracidade dos Dados.</strong>&nbsp;Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel pela precis&atilde;o, veracidade ou atualiza&ccedil;&atilde;o dos Dados que nos fornece. N&oacute;s n&atilde;o somos obrigados a tratar os seus Dados se houver raz&otilde;es para crer que tal Tratamento possa nos imputar infra&ccedil;&atilde;o de qualquer lei aplic&aacute;vel, ou se voc&ecirc; estiver utilizando nossos Sites para quaisquer fins ilegais ou il&iacute;citos.&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Base de Dados.</strong>&nbsp;A base de dados formada por meio da coleta de Dados &eacute; de nossa propriedade e est&aacute; sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos descritos nesta Pol&iacute;tica.;&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Tecnologias empregadas.</strong>&nbsp;N&oacute;s utilizamos <em>Cookies</em> em nossos ambientes, caso voc&ecirc; n&atilde;o tenha interesse em mant&ecirc;-los, dever&aacute; configurar seu navegador de internet para bloque&aacute;-los; no entanto, desde j&aacute; destacamos que nesta hip&oacute;tese, algumas funcionalidades que oferecemos poder&atilde;o ser limitadas.&nbsp;</p>
    <p class="text" align="justify">Todas as tecnologias utilizadas respeitar&atilde;o sempre a legisla&ccedil;&atilde;o vigente e os termos desta Pol&iacute;tica. N&oacute;s n&atilde;o utilizamos nenhum tipo de decis&atilde;o unicamente automatizada que impacte o Usu&aacute;rio.&nbsp;</p>
    <h1 class="topic">COMO UTILIZAMOS COOKIES</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><em>Cookies</em>&nbsp;s&atilde;o pequenos arquivos digitais em formato de texto que s&atilde;o armazenados no seu dispositivo (computador,&nbsp;<em>smartphone</em>,&nbsp;<em>tablet</em>&nbsp;etc.) pelo navegador de internet e que guardam informa&ccedil;&otilde;es relacionadas &agrave;s suas prefer&ecirc;ncias, como idioma preferido, localiza&ccedil;&atilde;o, recorr&ecirc;ncia das suas sess&otilde;es e outras vari&aacute;veis para tornar a sua experi&ecirc;ncia muito mais eficiente.</p>
    <p class="text" align="justify">Os&nbsp;<em>Cookies</em>&nbsp;servem para aprimorar a sua experi&ecirc;ncia, tanto em termos de performance, como em termos de usabilidade, uma vez que os conte&uacute;dos disponibilizados ser&atilde;o direcionados &agrave;s suas necessidades e expectativas.</p>
    <p class="text" align="justify">Eles tamb&eacute;m podem ser utilizados para realizar&#160;estat&iacute;sticas an&ocirc;nimas e agregadas que permitem entender como o usu&aacute;rio utiliza o site, bem como para aprimorar suas estruturas e conte&uacute;do. Por serem estat&iacute;sticas an&ocirc;nimas, n&atilde;o &eacute; poss&iacute;vel identific&aacute;-lo pessoalmente por meio desses dados.</p>
    
    <div style="padding:10px;width:90%;">
      <ul>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Necess&aacute;rios:</strong>&nbsp;Permitem a navega&ccedil;&atilde;o e utiliza&ccedil;&atilde;o das aplica&ccedil;&otilde;es, bem como acessar a &aacute;reas seguras do site. Sem estes <em>Cookies</em>&nbsp;, o site n&atilde;o funciona corretamente.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Desempenho/Anal&iacute;ticos:</strong>&nbsp;Coletam dados estat&iacute;sticos an&ocirc;nimos com a finalidade de analisar a utiliza&ccedil;&atilde;o do site e seu respectivo desempenho.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;de&nbsp;Funcionalidade:</strong>&nbsp;S&atilde;o utilizados para assegurar a disponibiliza&ccedil;&atilde;o de funcionalidades adicionais do site ou para guardar as prefer&ecirc;ncias definidas pelo Usu&aacute;rio ao navegar na internet, sempre que utilizar o mesmo dispositivo.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;de&nbsp;Publicidade:</strong>&nbsp;Direcionam a publicidade em fun&ccedil;&atilde;o dos interesses do Usu&aacute;rio, ajudando a medir o sucesso de aplica&ccedil;&otilde;es e a efic&aacute;cia da publicidade de parceiros.</li>
      </ul>
    </div>
    <p class="text" align="justify">A maioria dos navegadores de Internet &eacute; configurada para aceitar automaticamente os <em>Cookies</em>, mas voc&ecirc; pode alterar as configura&ccedil;&otilde;es para bloquear o uso de <em>Cookies</em>.&nbsp;Os navegadores que possuem navega&ccedil;&atilde;o an&ocirc;nima coletam apenas <em>cookies</em> estritamente necess&aacute;rios e de funcionalidade.&nbsp;</p>
    <p class="text" align="justify">Existem v&aacute;rias formas de gerenciar <em>Cookies</em>.&nbsp;Consulte as instru&ccedil;&otilde;es ou a se&ccedil;&atilde;o de ajuda,&nbsp;<em>tools</em>&nbsp;ou&nbsp;<em>edit</em>&nbsp;do seu navegador. Disponibilizamos abaixo alguns&nbsp;<em>links</em>&nbsp;para os navegadores mais conhecidos:</p>
    <div style="padding:10px;width:70%;">
      <ul>
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Firefox,<a class="link" style="text-decoration:none"  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">&nbsp;clique aqui</a></span></li>
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Chrome,<a class="link" style="text-decoration:none"  href="https://support.google.com/chrome/answer/95647?hl=pt-BR&amp;co=GENIE.Platform%3DDesktop">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Internet Explorer,<a class="link" style="text-decoration:none"  href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Safari,<a class="link" style="text-decoration:none"  href="https://support.apple.com/pt-br/guide/safari/sfri11471/">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Opera,<a class="link" style="text-decoration:none"  href="https://help.opera.com/en/latest/security-and-privacy/">&nbsp;clique aqui</a></span></li>       
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Microsoft Edge,<a class="link" style="text-decoration:none"  href="https://support.microsoft.com/pt-br/help/4026200/microsoft-edge-browse">&nbsp;clique aqui</a></span></li>           
      </ul>
    </div>
    <p>&nbsp;</p>
    <h1 class="topic">COMO COMPARTILHAMOS&nbsp;SEUS DADOS PESSOAIS</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">&Eacute; importante que voc&ecirc; saiba que, ressalvadas as hip&oacute;teses abaixo elencadas, a Vix Seminovos n&atilde;o compartilha seus Dados Pessoais e se necess&aacute;rio realizar algum compartilhamento somente far&aacute; para aqueles estritamente necess&aacute;rios ao cumprimento das atividades a que s&atilde;o destinados e, sempre que poss&iacute;vel, adota medidas para salvaguardar as informa&ccedil;&otilde;es.</p>
    <div style="padding:10px;width:90%;">
      <ul>
       <li class="text" align="justify">Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial;</li>           
       <li class="text" align="justify">Com as empresas e &aacute;reas de neg&oacute;cios do Grupo &Aacute;guia Branca, as quais est&atilde;o de acordo com esta Pol&iacute;tica;</li>           
       <li class="text" align="justify">Com as empresas da VIXPar (VIX, V1, Let&rsquo;s, AutoPort, EBEC e Servicarga), por motivos jur&iacute;dicos ou em conex&atilde;o com reclama&ccedil;&otilde;es, conflitos, processos judiciais e administrativos;</li>           
       <li class="text" align="justify">Com as empresas parceiras, quando necess&aacute;rio para viabilizar, facilitar ou executar atividade espec&iacute;fica;</li>           
       <li class="text" align="justify">De forma autom&aacute;tica, em caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o; e</li>
       <li class="text" align="justify">Para atender os interesses leg&iacute;timos e comerciais da Vix Seminovos e da VIXPar quando aplic&aacute;vel.</li>              
      </ul>
    </div>
    <p class="text" align="justify">Para eventuais atividades de intelig&ecirc;ncia de mercado, divulga&ccedil;&atilde;o de dados &agrave; imprensa e realiza&ccedil;&atilde;o de propagandas, os Dados Pessoais fornecidos por voc&ecirc; ser&atilde;o compartilhados de forma anonimizada, ou seja, de forma que n&atilde;o possibilite sua identifica&ccedil;&atilde;o.</p>
    <p class="text" align="justify">Caso voc&ecirc; tenha qualquer d&uacute;vida sobre com quem compartilhamos seus Dados Pessoais, por favor, entre em contato conosco por meio dos Canais de Atendimento disponibilizados nesta Pol&iacute;tica e lhe auxiliaremos a esclarecer os pontos.</p>
    <p class="text" align="justify">Para mais informa&ccedil;&otilde;es sobre como&#160;tratamos e compartilhamos seus Dados Pessoais com as empresas do Grupo, por gentileza,&#160;acesse&#160;o nosso portal de privacidade.</p>
    <div style="align:center; padding:10px;width:60%;" ref="divImg">
      <img :style="style" src="@/assets/privacy-policy/image1.png">
    </div>
    <h1 class="topic">COMO PROTEGEMOS SEUS DADOS</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Pr&aacute;ticas de Seguran&ccedil;a e Governan&ccedil;a.</strong>&nbsp;Para resguardar a sua privacidade e proteger os seus Dados, temos um programa de governan&ccedil;a que cont&eacute;m regras de boas pr&aacute;ticas, pol&iacute;ticas e procedimentos internos, os quais estabelecem condi&ccedil;&otilde;es de organiza&ccedil;&atilde;o, treinamentos, a&ccedil;&otilde;es educativas e mecanismos de supervis&atilde;o e mitiga&ccedil;&atilde;o de riscos relacionados ao Tratamento de Dados Pessoais.</p>
    <p class="text" align="justify"><strong class="sub-topic">Acesso aos Dados, proporcionalidade e relev&acirc;ncia.</strong>&nbsp;Internamente, os Dados coletados s&atilde;o acessados somente por profissionais devidamente autorizados, respeitando os princ&iacute;pios de proporcionalidade, necessidade e relev&acirc;ncia para os objetivos do nosso neg&oacute;cio, al&eacute;m do compromisso de confidencialidade e preserva&ccedil;&atilde;o da sua privacidade nos termos desta Pol&iacute;tica.</p>
    <p class="text" align="justify"><strong class="sub-topic">Ado&ccedil;&atilde;o de boas pr&aacute;ticas.</strong>&nbsp;Voc&ecirc; tamb&eacute;m &eacute; respons&aacute;vel pelo sigilo de seus Dados e deve ter sempre ci&ecirc;ncia de que o compartilhamento de senhas e dados de acesso viola esta Pol&iacute;tica e pode comprometer a seguran&ccedil;a dos seus Dados e do nosso Site. Caso voc&ecirc; identifique ou tome conhecimento sobre o comprometimento da seguran&ccedil;a dos seus Dados, entre em contato com o nosso Encarregado por meio dos Canais de Atendimento atrav&eacute;s do Portal de Privacidade&nbsp;<a class="link" href="https://portaldeprivacidade.vix.com.br/"><u>https://portaldeprivacidade.vix.com.br</u></a></p>
    <p class="text" align="justify"><strong class="sub-topic">Tratamento por terceiros sob nossa diretriz.</strong>&nbsp;N&oacute;s buscamos avaliar cuidadosamente nossos parceiros e prestadores de servi&ccedil;os e firmamos com eles obriga&ccedil;&otilde;es contratuais de confidencialidade, seguran&ccedil;a da informa&ccedil;&atilde;o e prote&ccedil;&atilde;o de Dados, com objetivo de proteger voc&ecirc;.</p>
    <p class="text" align="justify"><strong class="sub-topic">Links externos.</strong>&nbsp;Quando voc&ecirc; utilizar nossos ambientes, voc&ecirc; poder&aacute; ser conduzido, via link, a outros sites, portais ou plataformas, que poder&atilde;o coletar seus dados e ter sua pr&oacute;pria pol&iacute;tica de privacidade. Caber&aacute; a voc&ecirc; ler as referidas pol&iacute;ticas, sendo de sua responsabilidade aceit&aacute;-las ou rejeit&aacute;-las. N&oacute;s n&atilde;o somos respons&aacute;veis pelas pol&iacute;ticas de privacidade de terceiros e nem pelo conte&uacute;do de quaisquer sites ou servi&ccedil;os ligados a ambientes que n&atilde;o os nossos.</p>
    

    <h1 class="topic">COMO ARMAZENAMOS&nbsp;SEUS DADOS PESSOAIS E O REGISTRO DE&nbsp;ATIVIDADES</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Local de armazenamento:</strong>&nbsp;Os Dados Pessoais coletados s&atilde;o armazenados em ambiente seguro e controlado e nossos servidores est&atilde;o localizados no Brasil.</p>
    <p class="text" align="justify"><strong class="sub-topic">Prazo de armazenamento:</strong>&nbsp;N&oacute;s armazenamos os Dados Pessoais somente pelo tempo que for necess&aacute;rio para cumprir com as finalidades para as quais foram coletados ou para cumprimento de quaisquer obriga&ccedil;&otilde;es legais, regulat&oacute;rias ou para preserva&ccedil;&atilde;o de direitos. Lembre-se, este prazo pode superar o de sua rela&ccedil;&atilde;o direta com a&nbsp;VIX Seminovos,&nbsp;j&aacute; que determinadas obriga&ccedil;&otilde;es se estendem no tempo.</p>
    <p class="text" align="justify"><strong class="sub-topic">Descarte dos Dados:</strong>&nbsp;Decorrido&nbsp;o prazo de manuten&ccedil;&atilde;o e a necessidade legal, os Dados Pessoais s&atilde;o exclu&iacute;dos com uso de m&eacute;todos de descarte seguro ou utilizados de forma anonimizada para fins estat&iacute;sticos.&nbsp;</p>

    <h1 class="topic">QUAIS S&Atilde;O OS SEUS DIREITOS E COMO EXERC&Ecirc;-LOS</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">A Legisla&ccedil;&atilde;o de prote&ccedil;&atilde;o de dados traz uma s&eacute;rie de direitos que poder&atilde;o ser exercidos por voc&ecirc; atrav&eacute;s de requisi&ccedil;&atilde;o ao nosso Encarregado pelo Canal de Atendimento disponibilizado no final desta Pol&iacute;tica. Voc&ecirc; poder&aacute; solicitar:</p>
    <div style="padding:10px;width:90%;">
      <ul>     
        <li class="text" align="justify">A confirma&ccedil;&atilde;o do tratamento de dados e informa&ccedil;&otilde;es sobre o seu uso;</li>
        <li class="text" align="justify">Voc&ecirc; pode solicitar uma c&oacute;pia dos Dados Pessoais que n&oacute;s temos a seu respeito;</li>
        <li class="text" align="justify">Corrigir ou solicitar a corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados: o usu&aacute;rio deve atualizar seus dados pessoais cadastrais sempre que houver uma altera&ccedil;&atilde;o, isso garantir&aacute; a maior seguran&ccedil;a e melhora na presta&ccedil;&atilde;o dos servi&ccedil;os;</li>
        <li class="text" align="justify">Solicitar a exclus&atilde;o dos dados pessoais dos sistemas da VIX Seminovos. Todos os dados coletados ser&atilde;o exclu&iacute;dos dos servidores da VIX Seminovos quando o usu&aacute;rio assim requisitar ou quando estes n&atilde;o forem mais necess&aacute;rios ou relevantes para o oferecimento dos Servi&ccedil;os, salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o, como eventual obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados ou necessidade de preserva&ccedil;&atilde;o destes para resguardo de direitos;</li>
        <li class="text" align="justify">Solicitar a anonimiza&ccedil;&atilde;o, o bloqueio ou a elimina&ccedil;&atilde;o de seus dados: se o usu&aacute;rio considerar o uso dos seus dados pessoais como desnecess&aacute;rio e/ou excessivo;</li>
        <li class="text" align="justify">Solicitar a revis&atilde;o de decis&otilde;es que tenham sido tomadas de forma automatizada com base no uso de dados pessoais;</li>
        <li class="text" align="justify">Solicitar que o fornecimento de seus dados em formato estruturado e interoper&aacute;vel, visando sua transfer&ecirc;ncia para um terceiro, respeitando nossa propriedade intelectual ou segredo de neg&oacute;cios;</li>
        <li class="text" align="justify">Voc&ecirc; pode solicitar informa&ccedil;&otilde;es sobre o compartilhamento de seus Dados Pessoais. &Eacute; direito seu saber com quais entidades p&uacute;blicas e privadas a VIX Seminovos compartilha seus dados;</li>
        <li class="text" align="justify">Voc&ecirc; pode se opor &agrave;s atividades de tratamento que n&atilde;o dependam do consentimento. Neste caso, &eacute; poss&iacute;vel solicitar sua interrup&ccedil;&atilde;o;</li>
        <li class="text" align="justify">Por fim, voc&ecirc; tem o direito de n&atilde;o dar ou retirar seu consentimento e obter informa&ccedil;&otilde;es sobre as consequ&ecirc;ncias dessa escolha. Nesse caso, alguns dos servi&ccedil;os n&atilde;o poder&atilde;o ser prestados.</li>
      </ul>
    </div>
    <p class="text" align="justify"><strong class="sub-topic">Para exercer seus direitos, por gentileza, acesse o nosso Portal de Privacidade:&nbsp;</strong><a class="link" href="https://portaldeprivacidade.vix.com.br"><u>https://portaldeprivacidade.vix.com.br</u></a></p>
    <p class="text" align="justify">Para sua seguran&ccedil;a, sempre que voc&ecirc; apresentar uma requisi&ccedil;&atilde;o para exerc&iacute;cios dos seus direitos, n&oacute;s poderemos solicitar informa&ccedil;&otilde;es complementares para comprovar sua identidade, buscando impedir fraudes.</p>
    <p class="text" align="justify">&Eacute; importante que voc&ecirc; saiba que o exerc&iacute;cio de seus direitos n&atilde;o &eacute; ilimitado, a VIX Seminovos pode, em casos espec&iacute;ficos e autorizados por lei, deixar de atender alguma requisi&ccedil;&atilde;o. Caso isto ocorra, voc&ecirc; ser&aacute; informado sobre o motivo de nossa recusa. Voc&ecirc; tamb&eacute;m est&aacute; ciente de que, caso a solicita&ccedil;&atilde;o de acesso aos seus Dados Pessoais recaia sobre documentos espec&iacute;ficos, principalmente que envolvam Dados Pessoais de outras pessoas, somente seus Dados Pessoais ser&atilde;o fornecidos e n&atilde;o a integralidade dos documentos.</p>
    <p class="text" align="justify">A Vix Seminovos se compromete a responder todas as requisi&ccedil;&otilde;es em um prazo razo&aacute;vel e sempre em conformidade com a legisla&ccedil;&atilde;o aplic&aacute;vel.</p>

    <h1 class="topic">ALTERA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">A VIX Seminovos poder&aacute; alterar o teor desta Pol&iacute;tica a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente, cabendo a voc&ecirc; verific&aacute;-la sempre que efetuar o acesso em nossos ambientes. Ocorrendo atualiza&ccedil;&otilde;es neste documento e que demandem nova coleta de consentimento, voc&ecirc; ser&aacute; notificado por meio dos canais de contatos que nos informar.</p>
    <p class="text" align="justify">Caso algum ponto desta Pol&iacute;tica seja considerado inaplic&aacute;vel por autoridade de dados ou judicial, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</p>
    <p class="text" align="justify"><strong class="sub-topic">Lei aplic&aacute;vel e foro.</strong>&nbsp;Esta Pol&iacute;tica ser&aacute; interpretada segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o foro do seu domic&iacute;lio para dirimir qualquer controv&eacute;rsia que envolva este documento, salvo ressalva espec&iacute;fica de compet&ecirc;ncia pessoal, territorial ou funcional pela legisla&ccedil;&atilde;o aplic&aacute;vel.</p>

    <h1 class="topic">CONTATO&nbsp;</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">Em caso de qualquer d&uacute;vida com rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es constantes desta Pol&iacute;tica, inclusive para exerc&iacute;cio dos seus direitos, voc&ecirc; poder&aacute; entrar em contato com nosso Encarregado, que est&aacute; &agrave; disposi&ccedil;&atilde;o nos seguintes endere&ccedil;os:</p>   
    <div style="padding:10px;width:70%;">
      <ul>
        <li class="text" align="justify">Encarregado:&nbsp;Peck Advogados, representada por Cec&iacute;lia Helena de Castro na fun&ccedil;&atilde;o de Encarregada Titular e Henrique Rocha como Encarregado Substituto, CNPJ 42.797.473/0001-01, endere&ccedil;o Rua Henrique Schaumann, 270 - 4o andar, Edif&iacute;cio Pinheiros Corporate, Jardim Paulista, S&atilde;o Paulo, SP, CEP 05413-909.</li>
        <li class="text" align="justify">Endere&ccedil;o para correspond&ecirc;ncias: Avenida Jeronimo Vervloet, 345, Maria Ortiz, Vitoria/Esp&iacute;rito Santo, CEP: 29070- 350.</li>              
        <li class="text" align="justify">E-mail para contato:&nbsp;<a class="link" href="mailto:privacidade@vix.com.br">privacidade@vix.com.br</a>.</li>       
      </ul>
    </div>
    </section>
     
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data () {
    return {
      window: {
        width: 600,
        height: 150
      }
    }
  },
  computed: {
    style () {
      return `height: ${this.getSize(this.window.height)}; width: ${this.getSize(this.window.width)};`
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        const oldHeight = this.window.height
        const oldWidth = this.window.width
        //const height = this.$refs.divImg.offsetHeight
        const width = this.$refs.divImg.offsetWidth
        this.window.width = width
        this.window.height = width * oldHeight / oldWidth
      },
      getSize (size) {
        return size.toString()
          .replace(/\d/g, '') ? size : `${size}px`
      }
    }
}
</script>

<style scoped lang="scss">
.body { 
  font-family: "HelveticaNeueLTPro-Roman";
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  padding-top: 30px;
  font: 24px 'HelveticaNeueLTPro-Roman';
  font-size: 24px;  
  text-transform: uppercase;
  color: #cdd625;
}

.topic {
  color: #60BED4;
  font-size: 18px;  
}

.link {
  color: #60BED4; 
}

.sub-topic {
  color: #CDD43F;
}

.text {
  font-family: "HelveticaNeueLTPro-Roman";
  color: #939393;  
}

.table-vix {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 40px;
  color: #939393;
}

.table-dados-pessoais {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 40px;
  color: #939393;
}

.holder {   
  width: auto;
  display: inline-block;  
}


</style>